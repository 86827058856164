














import { Component, Inject as VueInject } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../abstract/ui'

import { ArticleCard } from '../../../front/content/organisms/ArticleCard'
import {
  translateContentToArticleCard
} from '../../../front/content/organisms/ArticleCard/ArticleCard.helpers'

import { ArticlesTeaserModule } from '../ArticlesTeaser.contracts'
import {
  ARTICLES_TEASER_CONFIG_MAP,
  ARTICLES_TEASER_KEY
} from '../ArticlesTeaser.config'
import { AnyObject } from '@movecloser/front-core'
import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../support'

/**
 * Container component for the `ArticlesTeaserModuleUi`.
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<ArticlesTeaserModuleUi>({
  name: 'ArticlesTeaserModuleUi',
  components: { ArticleCard },
  created () {
    this.config = this.getComponentConfig(ARTICLES_TEASER_KEY, { ...ARTICLES_TEASER_CONFIG_MAP })
  }
})
export class ArticlesTeaserModuleUi extends AbstractModuleUi<ArticlesTeaserModule> {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  /**
   * Articles teaser carousel config
   */
  public get carouselConfig (): AnyObject {
    const config = this.getConfigProperty('carouselConfig')
    return {
      ...config,
      responsive: {
        ...config.responsive,
        desktop: {
          ...config.responsive.desktop,
          perPage: this.data.content.count
        },
        mobile: {
          ...config.responsive.mobile,
          perPage: this.data.content.count
        }
      }
    }
  }

  public get buttonIcon (): boolean {
    return this.getConfigProperty('buttonIcon')
  }

  /**
   * Articles teaser content
   */
  public get articles () {
    if (!this.content.articles?.length) {
      return
    }

    return this.content.articles.map(translateContentToArticleCard)
  }

  /**
   * Articles teaser heading
   */
  public get heading () {
    if (!this.content.heading) {
      return
    }

    return this.content.heading
  }
}

export default ArticlesTeaserModuleUi
